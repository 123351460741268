<template>
    <section>
        <modal ref="modalCambioPrecio" titulo="Detalle del producto" tamano="modal-lg" no-aceptar adicional="Actualizar" @adicional="solicitar_cambio">
            <div class="row mx-5">
                <div class="col-3 px-0 text-center">
                    <img :src="producto.imagen" class="obj-cover br-12 border p-2" width="110" height="110" />
                    <div class="row mx-0 my-3 justify-center">
                        <el-popover placement="bottom" trigger="hover">
                            <div class="d-middle">
                                <i class="icon-pencil" />
                                <p class="br-5 px-2 cr-pointer f-12" @click="open_sugerencia = true">Cambiar Precio para este Cliente</p>
                            </div>
                            <div slot="reference" class="col-auto text-center br-10 bg-light-f5 cr-pointer p-1 px-1 border shadow mx-2">
                                <i class="icon-dots-horizontal f-20 text-general2" />
                            </div>
                        </el-popover>
                    </div>
                </div>
                <div class="col-9 px-0">
                    <p class="text-general f-14 f-600">
                        {{ producto.nombre }}
                    </p>
                    <div v-if="sugerencia.activa">
                        <div v-if="sugerencia.estado == 2 || sugerencia.estado == 3" class="row mx-0 my-2">
                            <p class="col-auto px-0 text-general f-13">
                                {{ producto.precio_sugerido }}
                            </p>
                            <p class="col-auto text-gris descuento f-600 f-13">
                                {{ convertMoneyTendero(producto.precio, pedido.idm_moneda) }}
                            </p>
                        </div>
                        <div v-else class="row mx-0 my-2">
                            <p class="col-auto px-0 text-general f-13">
                                {{ convertMoneyTendero(producto.precio, pedido.idm_moneda) }}
                            </p>
                        </div>
                        <div class="row mx-0 align-items-center">
                            <div class="bg-general3 text-white px-3 br-12 f-11">
                                {{ sugerencia.porcentaje }} ({{ sugerencia.estado == 1 ? 'Pendiente' : sugerencia.estado == 2 ? 'Aprobado' : sugerencia.estado == 3 ? 'Aprobado' : 'Rechazado' }})
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="row mx-0 my-2">
                            <p class="col-auto px-0 text-general f-13">
                                {{ promocion.activa ? promocion.valor : convertMoneyTendero(producto.precio, pedido.idm_moneda) }}
                            </p>
                            <p v-show="promocion.activa" class="col-auto text-gris descuento f-600 f-13">
                                {{ convertMoneyTendero(producto.precio, pedido.idm_moneda) }}
                            </p>
                        </div>
                        <div v-show="promocion.activa" class="row mx-0 align-items-center">
                            <div class="bg-general text-white px-3 br-12 f-11">
                                {{ promocion.texto }}
                            </div>
                        </div>
                    </div>
                    <p class="text-general my-2 f-13">
                        <b class="f-500">Presentacion: </b> {{ producto.presentacion }}
                    </p>
                    <p class="text-general my-2 f-13">
                        <b class="f-500"> Unidad de Medida: </b> {{ producto.unidad }}
                    </p>
                    <p class="text-general my-2 f-13">
                        <b class="f-500">Venta mínima:</b> {{ producto.cantidad_minima }} {{ producto.unidad }}
                    </p>
                    <div class="row mx-0 justify-content-between">
                        <p class="text-general col-auto px-0 f-13">
                            <b> Cantidad Pedida:</b> {{ producto.cantidad_pedida }}
                        </p>
                        <p class="text-general col-auto f-13">
                            <b> Valor Total:</b> {{ producto.total_pedida }}
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="open_sugerencia" class="row mx-5 text-general my-2">
                <div class="col-auto">
                    <p class="ml-2 f-13">Nuevo Precio</p>
                    <div class="d-middle">
                        <money v-model="sugerencia.unidad_sugerida" class="input-money" v-bind="money" />
                    </div>
                </div>
                <div class="col-5">
                    <p class="ml-2 f-13">Motivo del cambio de Precio</p>
                    <el-select v-model="sugerencia.id_motivo_solicitud" class="w-100" placeholder="Seleccionar motivo" size="small">
                        <el-option
                        v-for="item in motivos"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="d-flex align-items-end pb-1">
                    <el-tooltip class="cr-pointer" placement="bottom" content="Quitar Sugerencia" effect="light">
                        <i class="icon-devolver f-20" @click="open_sugerencia = false" />
                    </el-tooltip>
                </div>
            </div>
            <!-- <div class="row mx-5">
                <div class="w-100">
                    <label class="f-13">Comentario</label>
                    <el-input v-model="producto.comentario" type="textarea" :rows="2" show-word-limit maxlength="200" />
                </div>
            </div> -->
        </modal>
    </section>
</template>

<script>
import {Money} from 'v-money'
import {mapGetters} from 'vuex'
import Pedidos from '~/services/pedidos/pedidos-tienda'
export default {
    components: {
        Money,
    },
    data(){
        return {
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            motivos:[],
            open_sugerencia:false,

            producto:{
                id:0,
                nombre:'',
                descripcion:'',
                comentario:'',
                imagen:'',
                precio: 0,
                precio_final: 0,
                presentacion:'',
                unidad:'',
                cantidad_minima:0,
                cantidad_pedida: 0,
                total_pedida: 0
            },
            sugerencia:{
                activa:false,
                estado:0,
                id_motivo_solicitud:null,
                unidad_sugerida:0,
                porcentaje:''
            },
            promocion:{
                activa:false,
                texto:'',
                valor:0
            },
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos/id_pedido',
            pedido: 'pedidos/pedidos/pedido',
        })
    },
    methods: {
        toggle(prod){
            this.limpiar()
            if(this.motivos.length === 0){
                this.select_motivos()
            }

            let decimalesFrancion = 0
            if(prod.producto.cantidad_tipo === 1){
                decimalesFrancion = 0
            } else{
                decimalesFrancion = 2
            }

            this.money.precision = this.$tienda.decimales
            this.producto = {
                id:prod.producto.id,
                id_pedido_producto: prod.id,
                nombre:prod.producto.nombre,
                descripcion:prod.producto.descripcion,
                comentario:prod.estado_comentario,
                imagen:prod.producto.imagen,
                // precio: this.convertMoneyTendero(prod.unidad_teorica,this.pedido.idm_moneda),
                precio: prod.unidad_teorica,
                precio_final: this.convertMoneyTendero(prod.unidad_final,this.pedido.idm_moneda),
                presentacion:prod.producto.presentacion,
                unidad:prod.producto.unidad,
                cantidad_minima:this.agregarSeparadoresNumero(prod.producto.cantidad_minima,decimalesFrancion),
                cantidad_pedida: this.agregarSeparadoresNumero(prod.cantidad,decimalesFrancion) + prod.producto.unidad,
                total_pedida: this.convertMoneyTendero(prod.total_final,this.pedido.idm_moneda)
            }
            if(prod.sugerido){
                this.open_sugerencia = true
                this.producto.precio_sugerido = this.convertMoneyTendero(prod.sugerido.unidad_sugerida,this.pedido.idm_moneda),
                this.sugerencia.activa = true
                this.sugerencia.estado = prod.sugerido.estado
                this.sugerencia.id_motivo_solicitud = prod.sugerido.id_motivo_solicitud
                this.sugerencia.unidad_sugerida =  this.convertMoneyTendero(prod.sugerido.unidad_sugerida,this.pedido.idm_moneda)
                this.sugerencia.porcentaje = prod.sugerido.porcentaje
            }
            if(prod.promocion){
                this.promocion.activa = true
                this.promocion.texto = prod.data_promocion.texto
                this.promocion.valor =  this.convertMoneyTendero(prod.unidad_final,this.pedido.idm_moneda)
            }
            this.$refs.modalCambioPrecio.toggle();
        },
        async solicitar_cambio(){
            try {
                let model = {}
                if(this.open_sugerencia){
                    if(this.sugerencia.id_motivo_solicitud == null || this.sugerencia.unidad_sugerida == 0){

                        this.notificacion('Advertencia','Por favor digite un valor y seleccione un motivo para continuar','warning')
                        return
                    }

                    if(this.sugerencia.unidad_sugerida > parseFloat(this.producto.precio)){
                        this.notificacion('Advertencia',`No puede sugerir un precio mayor al valor actual. ${this.convertMoneyTendero(this.producto.precio,this.pedido.idm_moneda)}`,'warning')
                        return
                    }
                    model.sugerencia = true
                    model.id_motivo_solicitud = this.sugerencia.id_motivo_solicitud
                    model.unidad_sugerida = this.sugerencia.unidad_sugerida
                    model.id_producto = this.producto.id

                }else{
                    model.sugerencia = false
                    model.id_producto = this.producto.id
                }

                const {data} = await Pedidos.solicitar_cambio_precio(this.producto.id_pedido_producto,model)
                if(data.sin_accion){
                    this.$refs.modalCambioPrecio.toggle();
                    return
                }
                this.$store.dispatch('pedidos/pedidos/pedidos_productos', Number(this.id_pedido))
                this.notificacion('Éxito','Registro actualizado','success')

                this.$refs.modalCambioPrecio.toggle();


            } catch (e){
                this.error_catch(e)
            }
        },
        async select_motivos(){
            try {
                let params = { tipo:21 }
                const {data} = await Pedidos.select_motivos(params)
                this.motivos = data.motivos

            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.open_sugerencia = false
            this.producto = {
                id:0,
                nombre:'',
                descripcion:'',
                comentario:'',
                imagen:'',
                precio: 0,
                precio_final: 0,
                presentacion:'',
                unidad:'',
                cantidad_minima:0,
                cantidad_pedida: 0,
                total_pedida: 0
            }
            this.sugerencia={
                activa:false,
                estado:0,
                id_motivo_solicitud:null,
                unidad_sugerida:0,
                porcentaje:''
            }
            this.promocion={
                activa:false,
                texto:'',
                valor:0
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
</style>
